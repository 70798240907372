<template>
  <div class="dotsContextMenu">
    <slot name="icon" :id="dot_uid" draggable=false class="unselectable" ></slot>
    <img v-if="!slot_icon_is_not_empty" :id="dot_uid" src="../../../assets/more.svg" draggable=false class="unselectable"/>
    <transition name="bounce">
      <div v-if="menuDisplay" class="contextMenu" :class="{onTop : displayOnTop}" :id="contextMenu_uid" ref="menuRef" >
        <div v-if="state == 0">
          <p @click="rename">Renommer</p>
          <p @click="duplicateVideo(vdo_id)">Dupliquer</p>
          <!-- <p v-if="getVdo_errors.global.includes(vdo_id) == false" @click="pushInProd">Envoyer en tournage</p>-->
          <p class="remove" @click="deleteVideo(vdo_id)">Supprimer</p>
        </div>
        <div v-if="state == 1 && isAdmin">
          <p @click="rename">Renomer</p>
          <p @click="backToFirstStage">Retour en création</p>
          <p @click="shootingDone(vdo_id)">Fin de tournage</p>
          <p class="remove" @click="deleteVideo(vdo_id)">Supprimer</p>
        </div>

        <div v-if="state == 2 ">
          <p v-if="!vdoHasSubitles(vdo_id)" @click="subtitles_request({vdo_id : vdo_id})">Demander mes sous titres</p>
        </div>

        <div v-if="lines && lines.length > 0">
          <p v-for="(line, index) in lines" :key="`${line}_${index}`" @click="$emit('clicked', {'line' : line, 'index' : index} )">{{line}}</p>
        </div>
        
      </div>
    </transition>
  </div>
</template>

<script>

//NPM
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import Vuex from "vuex"
 
//STORE
import store from "../../../store"

export default {
  name: "dotsVdoMenu",
  store: store,
  props: ["vdo_id", "state", "lines"],
  emits: [ "pushInProd" , "backToFirstStage", "subtitles_request", "clicked"],

  data() {
    return {
      menuDisplay: false,
      displayOnTop : false
    };
  },
  created() {
    this.contextMenu_uid = uuidv4();
    this.dot_uid = uuidv4();
  },
  mounted() {
    document.addEventListener("click", (evt) => {
      var clickContextMenu = false;
      var clickOnDot = false;

      if (
        $(evt.target).attr("id") != undefined &&
        $(evt.target).attr("id") == this.dot_uid
      ) {
        clickOnDot = true;
      }

      $(evt.target)
        .parents()
        .each((/*index, element*/) => {
          if (
            $(this).attr("id") != undefined &&
            $(this).attr("id") == this.contextMenu_uid
          ) {
            clickContextMenu = true;
          }
        });

      if (clickOnDot == true) {
        this.menuDisplay = true;
      } else if (clickContextMenu == false) {
        this.menuDisplay = false;
      }
    });
  },
  computed : {
    ...Vuex.mapGetters([
      "isAdmin",
      "getVdo_errors",
      "vdoHasSubitles"
    ]),
    slot_icon_is_not_empty(){
      //console.log(this.$slots)
      return !!this.$slots.default
    }
  },
  watch : {
    menuDisplay(){
      const list = $('#videoList')
      let rowPosition;
      //Find parent row
      list.children().get().forEach(child=>{
        if ($(child).find(`#${this.dot_uid}`).length >= 1){
          rowPosition = $(child).position().top
        }
      })
      
      const treshold = list.innerHeight() - 120
      
      if (rowPosition >= treshold && treshold > 50){
        this.displayOnTop = true
      }
    }
  },
  methods: {
    rename() {
      $(".vdo_name#" + this.vdo_id + " > a")[0].click();
    },
    pushInProd() {
      this.$emit("pushInProd", this.vdo_id);
    },
    backToFirstStage() {
      this.$emit("backToFirstStage", this.vdo_id);
    },
    ...Vuex.mapActions([
      "deleteVideo",
      "duplicateVideo",
      "shootingDone",
      "subtitles_request"
    ])
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./style.css" scoped/>