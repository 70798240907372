<template>
    <div class="panel scriptEdit" id="script" panel-position="0">
        <p class="navigation_title">
            <a @click="back">Tableau de bord</a>
            <a> / </a>
            <a> Ecriture du script </a>
            <a> / </a>
            <a v-if="vdo_name != ''"> {{ vdo_name }} </a>
            <a v-else> Sans nom </a>
        </p>
        <h1 class="duration" v-if="vdo_duration != 0">
            Durée de vidéo estimée : {{ vdo_duration_render(vdo_duration) }}
        </h1>
    
        <autosaveinfo :data="autoSave" @save="save()" class="autosaveinfo" />
    
        <!-- WELCOMING -->
        <div v-if="script != null && script.length == 0" class="welcoming">
            <h2 class="header">
                <img src="../../../assets/script_white.png" />Démarrer un script
            </h2>
            <div class="flexRow">
                <div class="needHelp">
                    <h2>Inspiration</h2>
                    <h3>Quel type de vidéo voulez-vous faire ?</h3>
                    <SearchBar class="searchBar center" v-if="false"/>
                    <div class="scriptTemplateChoiceGrid center">
                        <p class="scriptTemplateChoice" v-for="timelineTemplate in timelineTemplates" :key="timelineTemplate" @click="loadTemplate(timelineTemplate)">
                            <EditableImage :src_img="timelineTemplate.timeline_template_image" class="icon" />
                            <a>{{ timelineTemplate.timeline_template_name }}</a>
                        </p>
                    </div>
                </div>
                <div class="fromScratch" @click="add_default">
                    <h2>Page blanche</h2>
                    <h3>Vous savez ce que vous voulez écrire ?</h3>
                    <p class="scriptTemplateChoice">
                        Let's go !<img src="../../../assets/rocket.png" />
                    </p>
                </div>
            </div>
        </div>
    
        <section class="center script" id="script_pannel" @keyup="triggerAutoSave">
            <div v-for="(singleContent, index) in script" v-bind:key="singleContent._id" :class="{
                      script_part: true,
                      unselectable: true,
                      ydecay: dragging_data.hover_index == index,
                      smooth_margin_bottom: true,
                      expand_margin_bottom_for_plus_button:
                        indexView.show_add_script_part_pannel != index &&
                        indexView.show_add_script_part_plus_button == index,
                      expand_margin_bottom_for_adding_pannel:
                        indexView.show_add_script_part_pannel == index,
                      expanded: indexView.expanded == index,
                      linked: singleContent.linked == true,
                      last: index == script.length - 1,
                      text: singleContent != undefined && singleContent.clip_type == 1,
                    }" :index="index" @mousedown="mousePressedDown($event, index)" @dragover.prevent @dragenter.prevent>
                <div class="trash_hover_zone_expander"></div>
    
                <!-- TRASH -->
                <span :class="{ trash: true, hide: index > 0 && script[index - 1].linked == true,}" @click="deletePart($event, index)"><span></span><i></i
                    ></span>
            
                    <!-- LINK -->
                    <div
                      :class="{
                        undraggable: true,
                        link_hover_zone_expander: true,
                        linked: singleContent.linked == true,
                      }"
                      @click="addLink(index)"
                    ></div>
                    <div
                      :class="{
                        link: true,
                        undraggable: true,
                        linked: singleContent.linked == true,
                      }"
                      @click="addLink(index)"
                    >
                      <svg
                        class="link_ext_part"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 312.6 312.6"
                        style="enable-background: new 0 0 312.6 312.6"
                        xml:space="preserve"
                      >
                        <path
                          class="st0"
                          d="M76.8,148.8c0,0-7-7-27-27c-62-62,23-133,73-83c18,17,32,31,32,31"
                        />
                        <path
                          class="st1"
                          d="M244,154.9c0,0,7,7,27,27c62,62-23,133-73,83c-18-17-32-31-32-31"
                        />
                      </svg>
                      <svg
                        class="link_center_part"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 312.6 312.6"
                        style="enable-background: new 0 0 312.6 312.6"
                        xml:space="preserve"
                      >
                        <line class="st0" x1="105.8" y1="203.8" x2="204.9" y2="104.7" />
                      </svg>
            
                      <svg
                        class="link_stroke"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 256 256"
                        style="enable-background: new 0 0 256 256"
                        xml:space="preserve"
                      >
                        <path class="st0" d="M2.5,4.5c0,180,59,226,138,226" />
                      </svg>
                      <svg
                        class="link_strokeup"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 256 256"
                        style="enable-background: new 0 0 256 256"
                        xml:space="preserve"
                      >
                        <path class="st0" d="M2.5,4.5c0,180,59,226,138,226" />
                      </svg>
                    </div>
            
                    <!-- ADD PART-->
                    <div
                      class="addpart_hover_zone_expander center"
                      @mouseover="indexView.show_add_script_part_plus_button = index"
                      @mouseleave="indexView.show_add_script_part_plus_button = null"
                    >
                      <img
                        src="../../../assets/plus-circle.svg"
                        class="plus_circle_button center"
                        @click="show_add_script_part_pannel($event, index)"
                      />
                      <div
                        class="add_part_to_script_pannel flexRow"
                        :ref="`add_script_part_pannel_${index}`"
                        v-if="indexView.show_add_script_part_pannel == index"
                      >
                        <!-- <div  v-if="introIndex == null && (outroIndex == null || index < outroIndex)" class="choice" @click="add_script_part(index + 1, 'intro')">-->
                        <div
                          :class="{
                            choice: true,
                            disable:
                              introIndex != null &&
                              (outroIndex == null || index < outroIndex),
                          }"
                          @click="add_script_part(index + 1, 'intro')"
                          style="--background_color: 68, 188, 225"
                        >
                          <img src="../../../assets/animation.svg" class="icon" />
                          <p>Animation d'introduction</p>
                        </div>
                        <div
                          class="choice double"
                          style="--background_color: 68, 227, 169"
                          @click="add_script_part(index + 1, 'title')"
                        >
                          <img src="../../../assets/title.svg" class="icon" />
                          <p>Animation titre</p>
                          <p>+</p>
                          <img
                            src="../../../assets/icon_script_part_speaker.svg"
                            class="icon"
                            style="--background_color: 244, 180, 106"
                          />
                          <p>Texte speaker</p>
                        </div>
                        <div
                          class="choice"
                          @click="add_script_part(index + 1, 'text')"
                          style="--background_color: 244, 180, 106"
                        >
                          <img
                            src="../../../assets/icon_script_part_speaker.svg"
                            class="icon"
                          />
                          <p>Texte speaker</p>
                        </div>
                        <div
                          :class="{
                            choice: true,
                            disable: outroIndex != null && index >= introIndex,
                          }"
                          @click="add_script_part(index + 1, 'outro')"
                          style="--background_color: 194, 153, 245"
                        >
                          <img src="../../../assets/animation.svg" class="icon" />
                          <p>Animation d'outro</p>
                        </div>
                      </div>
                    </div>
            
                    <!-- HEADER INTRODUCTION -->
                    <div
                      class="header flexRow"
                      v-if="
                        singleContent != undefined &&
                        singleContent.clip_type == 0 &&
                        singleContent.anim_type == 0
                      "
                    >
                      <img
                        src="../../../assets/animation.svg"
                        style="background-color: #44bce1"
                        class="icon"
                      />
                      <p>Animation d'introduction</p>
                    </div>
            
                    <!-- EXPANDED INTRODUCTION -->
                    <div
                      class="expanded_part"
                      v-if="
                        singleContent != undefined &&
                        singleContent.clip_type == 0 &&
                        singleContent.anim_type == 0
                      "
                    >
                      <transition name="fade">
                        <animationViewer
                          @click="pannelIdentity = true"
                          v-if="indexView.expanded == index"
                          class="animation center undraggable"
                          :animation_id="identity.ident_intro || null"
                          :default_feature_value="{
                            colors: identity.ident_colors,
                            font: identity.ident_font,
                            logo: identity.ident_logo,
                          }"
                          :clickable="true"
                        />
                      </transition>
                    </div>
            
                    <!-- HEADER TITRE -->
                    <div
                      class="header flexRow"
                      v-if="
                        singleContent != undefined &&
                        singleContent.clip_type == 0 &&
                        singleContent.anim_type == 1
                      "
                    >
                      <img
                        src="../../../assets/title.svg"
                        style="background-color: #44e3a9"
                        class="icon"
                      />
                      <div>
                        <p
                          class="header_anim_title_content"
                          v-if="singleContent.title_content != ''"
                        >
                          {{ singleContent.title_content }}
                        </p>
                        <p v-else>Non défini</p>
                        <p>Animation titre</p>
                      </div>
                    </div>
            
                    <!-- EXPANDED TITLE -->
                    <div
                      class="expanded_part expanded_part_title"
                      v-if="
                        singleContent != undefined &&
                        singleContent.clip_type == 0 &&
                        singleContent.anim_type == 1
                      "
                    >
                      <transition name="fade">
                        <animationViewer
                          @click="pannelIdentity = true"
                          v-if="indexView.expanded == index"
                          class="animation center undraggable"
                          :animation_id="
                            singleContent.anim_custom_id || identity.ident_title || null
                          "
                          :default_feature_value="{
                            text: singleContent.title_content,
                            colors: identity.ident_colors,
                            font: identity.ident_font,
                            logo: identity.ident_logo,
                          }"
                          :clickable="true"
                        />
                      </transition>
                      <input
                        :name="'input' + index"
                        :id="'input' + index"
                        type="text"
                        class="center undraggable"
                        placeholder="Inter-titre"
                        v-model="singleContent.title_content"
                      />
                    </div>
            
                    <!-- HEADER TEXTE -->
                    <div
                      class="header flexRow"
                      v-if="singleContent != undefined && singleContent.clip_type == 1"
                    >
                      <img
                        src="../../../assets/icon_script_part_speaker.svg"
                        style="background-color: #f4b46a"
                        class="icon"
                      />
                      <div>
                        <p v-if="singleContent.scrpt_text != ''">
                          <i>"{{ singleContent.scrpt_text }}"</i>
                </p>
                <p v-else>Non défini</p>
                <p>Texte speaker</p>
            </div>
    </div>
    
    <!-- EXPANDED TEXT -->
    <div class="expanded_part" v-if="singleContent != undefined && singleContent.clip_type == 1">
        <textarea @keyup="autoResizeTextarea()" v-model="singleContent.scrpt_text" singleContent.scrpt_text></textarea>
        <div class="words_info">
            <p>
                <a>{{ countWords(index) }} mots </a>
                <a v-if="
                              singleContent.estimated_duration &&
                              singleContent.estimated_duration != 0
                            ">/
                            {{ vdo_duration_render(singleContent.estimated_duration) }}</a
                          >
                        </p>
                      </div>
                    </div>
            
                    <!-- HEADER OUTRO -->
                    <div
                      class="header flexRow"
                      v-if="
                        singleContent != undefined &&
                        singleContent.clip_type == 0 &&
                        singleContent.anim_type == 2
                      "
                    >
                      <img
                        src="../../../assets/animation.svg"
                        style="background-color: #c298f5"
                        class="icon"
                      />
                      <p>Animation d'outro</p>
                    </div>
            
                    <!-- EXPANDED OUTRO -->
                    <div
                      class="expanded_part"
                      v-if="
                        singleContent != undefined &&
                        singleContent.clip_type == 0 &&
                        singleContent.anim_type == 2
                      "
                    >
                      <transition name="fade">
                        <animationViewer
                          @click="pannelIdentity = true"
                          v-if="indexView.expanded == index"
                          class="animation center undraggable"
                          :animation_id="identity.ident_outro || null"
                          :default_feature_value="{
                            colors: identity.ident_colors,
                            font: identity.ident_font,
                            logo: identity.ident_logo,
                          }"
                          :clickable="true"
                        />
                      </transition>
                    </div>
                  </div>
                  <div
                    v-if="script != null"
                     :class="{
                      script_part: true,
                      unselectable: true,
                      ydecay: dragging_data.hover_index == script.length,
                      script_part_placeholder: true,
                    }"
                    :index="script.length"
                  ></div>

                    <!--
                  <div class="reset_button_container">
                  </div>
                  -->
            
                  <!-- Back button -->
                  <div class="end_buttons_container">
                    <p class="button resetScript" v-if='script != undefined && script != null && script.length > 0' button-type="" @click="resetScript" :secondClick="false" ref="resetScript">Recommencer mon script</p>
                    <p class="button" button-type="" @click="back">Enregistrer</p>
                  </div>
            
                  <!-- SAVE A TEMPLATE -->
                  <saveTemplate
                    v-if="script != undefined && script.length > 0 && isAdmin == true"
                    :templateUsed="{ ...templateUsed, timeline_template_data: script }"
                  />
                </section>
            
                <!-- IDENTITY CHOICE -->
                <transition name="panel-slide">
                  <IdentityChoice
                    panel-position="50"
                    class="identity_pannel"
                    :back_button_title="true"
                    v-if="pannelIdentity"
                    v-bind:vdo_ids="vdo_id"
                    v-bind:usr_id="usr_id"
                    @finished="identityChange"
                  />
                </transition>
              </div>
</template>

<script>
// LIBS
import snackbar from "../../Object/snackbar";
import videoDuration from "../../../lib/videoDuration";
import $ from "jquery";
import axios from "axios";
import emitter from "tiny-emitter/instance";
import ClickOutside from "vue-click-outside";

//COMPONENTS
import autosaveinfo from "../../Object/AutoSaveInfo";
import animationViewer from "../../Object/animationViewer";
import IdentityChoice from "../IdentityChoice/IdentityChoice";
import SearchBar from "./searchBar/searchBar.vue";
import EditableImage from "../../Object/EditableImage";
import saveTemplate from "./saveTemplate/saveTemplate.vue";

//STORE
import Vuex from "vuex";
import store from "./../../../store/index";
import file_api from "../../../api/file";

export default {
    name: "scriptedit",
    props: ["vdo_id", "active"],
    components: {
        EditableImage,
        autosaveinfo,
        animationViewer,
        IdentityChoice,
        SearchBar,
        saveTemplate,
    },
    store: { store },
    data: function() {
        return {
            vdo_name: "",
            usr_id: null,
            stopScroll: true,
            script: null,
            vdo_duration: 0,
            autoSave: {
                status: 1,
                lastKeyUp: Date,
                lastSave: "",
            },
            identity: {
                ident_title: null,
                ident_colors: null,
                ident_font: null,
                ident_logo: null,
                ident_intro: null,
                ident_outro: null,
            },
            indexView: {
                button: null,
                expanded: null,
                menuButton: null,
                show_add_script_part_plus_button: null,
                show_add_script_part_pannel: null,
            },
            dragging_data: {
                drag_started: false,
                index: null,
                list_index: [],
                hover_index: null,
                shiftX_list: [],
                shiftY_list: [],
                height: null,
            },
            cursorPosForScroll: null,
            pannelIdentity: false,
            templateUsed: {
                timeline_template_image: undefined,
                timeline_template_data: undefined,
                timeline_template_name: undefined,
                _id: undefined,
            },
        };
    },
    directives: {
        ClickOutside,
    },
    mounted() {
        //Getting info
        axios
            .get("/videos/" + this.vdo_id)
            .then((res) => {
                if (res.data.updated_at != undefined) {
                    this.autoSave.lastSave = new Date(res.data.updated_at);
                }

                this.vdo_name = res.data.vdo_name;
                this.usr_id = res.data.user._id;
                if (res.data.identity != undefined) {
                    this.identity = res.data.identity;

                    //Identity logo id to image blod
                    this.identity_logoId_to_blob();

                    //Font id to font data
                    this.identity_fontId_to_data();
                }
                if (
                    res.data.vdo_timeline != undefined &&
                    res.data.vdo_timeline.length > 0
                ) {
                    this.script = res.data.vdo_timeline;
                    this.vdo_duration = res.data.vdo_duration || 0;

                    //console.debug(res.data);
                } else {
                    this.script = [];
                    this.add_default;
                }
            })
            .catch((err) => {
                snackbar.show({
                    text: err.response != undefined ? err.response.data : err,
                    pos: snackbar.pos,
                    showAction: false,
                    backgroundColor: snackbar.backgroundColor,
                    duration: snackbar.duration,
                    textColor: snackbar.colorLevel["error"],
                });
                setTimeout(() => {
                    this.$emit("finished");
                }, 500);
            });

        //FETCH TEMPLATE LIST
        this.fetch_timeline_template();

        //VERIFY ANIMATION MODIFIER FUNCTION PRECENCE
        this.fetch_animationModifierFunction();

        //Say location of user to app.vue
        setTimeout(() => {
            emitter.emit("click_location", "panel1");
        }, 500);

        /*
        //AUTO SAVE ON TEXT AREA AND INPUT TEXT
        $("textarea, input[type=text]").on("keyup", () => {
          //AUTO RESIZE SCRIPT PART HEIGHT DEPING ON TEXTAREA
          this.autoResizeTextarea()
          console.log('Auto resize')
          this.triggerAutoSave()
        });
        */

        //AUTO SCROLL ??
        $(".draggable").on("drag", function(e) {
            this.stopScroll = true;
            if (evt.clientY < 150) {
                this.stopScroll = false;
                this.scroll(-1);
            }

            if (evt.clientY > $("#script").outerHeight() - 150) {
                this.stopScroll = false;
                this.scroll(1);
            }
        });

        //DETECT CLICK OUTSIDE SCRIPT PARTS TO COLLA
        document.addEventListener("click", (evt) => {
            var script_pannel_found = false;
            var identity_pannel_found = false;

            $(evt.target)
                .parents()
                .each((index, parent) => {
                    if ($(parent).is("#script_pannel")) {
                        script_pannel_found = true;
                    }

                    if ($(parent).is(".identity_pannel")) {
                        identity_pannel_found = true;
                    }
                });

            //If click isn't on the script pannel collapse all script part
            if (script_pannel_found == false && identity_pannel_found == false) {
                //Delete .script_part style
                $(".script_part").removeAttr("style");

                //Waitng for .script_part collapse
                setTimeout(() => {
                    this.indexView.show_add_script_part_plus_button = null;
                    this.indexView.show_add_script_part_pannel = null;
                    this.indexView.expanded = null;
                }, 200);
            }
        });

        //DETECT MOUSEOVER SCRIPT PART
        document
            .getElementById("script_pannel")
            .addEventListener("mousemove", (mouseMoveEvt) => {
                //IF MOUSE IS CURRENTLY DRAGGING
                if (this.dragging_data.index != null) {
                    var margin_bottom = Number(
                        $(".script_part").css("margin-bottom").replace(/px|%/, "")
                    );

                    //Getting script div pos to calculate absolute position of elements
                    var scriptPannelOffset = $("#script_pannel").offset().top;

                    $("div.script_part")
                        .not(".dragging")
                        .each((index, element) => {
                            var blockStart =
                                Number($(element).attr("blockStartPos")) +
                                scriptPannelOffset -
                                margin_bottom / 2;
                            var blockEnd =
                                Number($(element).attr("blockEndPos")) +
                                scriptPannelOffset +
                                margin_bottom / 2;
                            var blockMiddle = (blockEnd + blockStart) / 2;
                            var startOfBlock = Number($(element).attr("startOfBlock"));
                            var endOfBlock = Number($(element).attr("endOfBlock"));

                            var new_hover_index = null;

                            //CALCULATE THE CENTER OF DRAGGING ELEMENT
                            var draggingCenter =
                                mouseMoveEvt.clientY -
                                this.dragging_data.shiftY_list[0] +
                                this.dragging_data.height / 2;

                            //if mouse dragging is in first half part
                            if (
                                blockStart <= draggingCenter &&
                                draggingCenter < blockMiddle
                            ) {
                                new_hover_index = Number(
                                    $("div.script_part")
                                    .not(".dragging")
                                    .eq(index + startOfBlock)
                                    .attr("index")
                                ); //Get index of next non dragging element with block count offset
                                //Else if mouse dragging is in 2e half part
                            } else if (
                                blockMiddle <= draggingCenter &&
                                draggingCenter <= blockEnd
                            ) {
                                new_hover_index = Number(
                                    $("div.script_part")
                                    .not(".dragging")
                                    .eq(index + 1 + endOfBlock)
                                    .attr("index")
                                );
                            }

                            //If Index deducted is one of index dragging, set hover_index on -1 to prevent the margin-top effect on dragging elements
                            if (
                                this.dragging_data.list_index.includes(new_hover_index) == true
                            ) {
                                console.warn(
                                    "index hover estimated is same as dragging element : ",
                                    new_hover_index
                                );
                                new_hover_index = null;
                            }

                            if (new_hover_index != null) {
                                this.dragging_data.hover_index = new_hover_index;
                            }
                        });
                }
            });
    },

    watch: {
        script: {
            deep: true,
            handler: function(script) {
                setTimeout(() => {
                    //Getting script div pos to calculate relative position of element depending on script. (To add script element deping on scroll position in other methods
                    var scriptPannelOffset = $("#script_pannel").offset().top;

                    //When script order change
                    //Or when element become linked or unlinked

                    $("div.script_part").each((index, element) => {
                        var blockSize = 1;
                        var startOfBlock = 0;
                        var endOfBlock = 0;

                        //Getting start position of LinkedBlock before the elem if element is linked
                        var blockStartPos = $(element).offset().top;
                        var i = index - 1;
                        while (i >= 0 && script[i]!= undefined && script[i].linked != undefined && script[i].linked == true) {
                            blockStartPos = $(`div.script_part:eq(${i})`).offset().top;
                            startOfBlock--;
                            i--;
                        }

                        //Now Getting end position of LinkedBlock before the elem if element is linked
                        var blockEndPos = $(element).offset().top + $(element).outerHeight();
                        var i = index;
                        while (i < script.length && script[i]!= undefined && script[i].linked != undefined && script[i].linked == true) {
                            blockEndPos = $(`div.script_part:eq(${i + 1})`).offset().top + $(`div.script_part:eq(${i + 1})`).outerHeight();
                            endOfBlock++;
                            i++;
                        }

                        //Store middle Y coordinate of linked block as attribut
                        $(element)
                            .attr("blockStartPos", blockStartPos - scriptPannelOffset)
                            .attr("blockEndPos", blockEndPos - scriptPannelOffset)
                            .attr("startOfBlock", startOfBlock)
                            .attr("endOfBlock", endOfBlock);
                    });


                }, 1000);
            },
        },
        cursorPosForScroll: {
            handler: function(val) {
                if (val != null) {
                    this.scrollIntervaler = setInterval(() => {
                        //While cursor is at extremity of screen
                        if (this.cursorPosForScroll != null) {
                            const scriptDiv = document.getElementById("script");

                            switch (this.cursorPosForScroll) {
                                case "down":
                                    scriptDiv.scrollTop++;
                                    break;

                                case "up":
                                    scriptDiv.scrollTop--;
                                    break;

                                default:
                                    clearInterval(this.scrollIntervaler);
                                    break;
                            }
                        } else {
                            clearInterval(this.scrollIntervaler);
                        }
                    }, 8);
                }
            },
        },
    },
    computed: {
        firstTitleIndex() {
            let index = this.script.findIndex((e) => e.anim_type == 1);
            if (index != "-1") {
                return index;
            } else {
                return null;
            }
        },

        outroIndex() {
            let index = this.script.findIndex((e) => e.anim_type == 2);
            if (index != "-1") {
                return index;
            } else {
                return null;
            }
        },
        introIndex() {
            let index = this.script.findIndex((e) => e.anim_type == 0);
            if (index != "-1") {
                return index;
            } else {
                return null;
            }
        },
        ...Vuex.mapGetters(["timelineTemplates", "isAdmin"]),
    },
    methods: {
        ...Vuex.mapActions([
            "updateVideo",
            "fetch_animationModifierFunction",
            "fetch_timeline_template",
            "get_font_animation",
        ]),
        loadTemplate(template) {
            //console.log(template.timeline_template_data)
            this.templateUsed = template;
            this.script = template.timeline_template_data;
        },
        identityChange(data) {
            this.stopScroll = true;
            this.pannelIdentity = false;
            if (data != undefined){ //If clicked on "choisir" button (emit content data)
                this.identity = data.identity;
                this.identity_logoId_to_blob();
                this.identity_fontId_to_data();
            } else { //Else, just fetch idenity with potentiel changements
               axios.get(`/identities/${this.identity._id}`).then((res) => {
                   this.identity = res.data;
                   this.identity_logoId_to_blob();
                    this.identity_fontId_to_data();
               })
            }
            
        },
        identity_logoId_to_blob() {
            if (this.identity.ident_logo != undefined) {
                file_api.get(this.identity.ident_logo).then((bloblUrl) => {
                    this.identity.ident_logo = bloblUrl;
                });
            }
        },
        identity_fontId_to_data() {
            if (this.identity.ident_font != undefined) {
                this.get_font_animation(this.identity.ident_font)
                    .then((font) => {
                        this.identity.ident_font = font;
                    })
                    .catch((e) => {
                        console.error(e);
                        //this.font_selector_placeholder = "Police d'écriture"
                    });
            }
        },
        vdo_duration_render: videoDuration.render,
        add_script_part: function(index, type) {
            this.indexView.menuButton = null;
            this.indexView.expanded = null;
            this.indexView.button = null;
            switch (type) {
                case "text":
                    this.script.splice(index, 0, {
                        clip_type: 1,
                        scrpt_text: "",
                    });
                    setTimeout(() => {
                        this.expand(null, index);
                    }, 200);
                    this.triggerAutoSave();
                    break;

                case "title":
                    this.script.splice(index, 0, {
                        clip_type: 0,
                        title_content: "",
                        anim_type: 1,
                        linked: true,
                    });
                    this.$nextTick(() => {
                        this.script.splice(index + 1, 0, {
                            clip_type: 1,
                            scrpt_text: "",
                        });
                        setTimeout(() => {
                            this.expand(null, index + 1);
                        }, 200);
                        this.triggerAutoSave();
                    });
                    break;

                case "intro":
                    this.script.splice(index, 0, {
                        clip_type: 0,
                        title_content: "",
                        anim_type: 0,
                    });
                    setTimeout(() => {
                        this.expand(null, index);
                    }, 200);
                    this.triggerAutoSave();
                    break;

                case "outro":
                    this.script.splice(index, 0, {
                        clip_type: 0,
                        title_content: "",
                        anim_type: 2,
                    });
                    setTimeout(() => {
                        this.expand(null, index);
                    }, 200);
                    this.triggerAutoSave();
                    break;

                default:
                    break;
            }

            //Hide all potentials adding pannel
            this.indexView.show_add_script_part_pannel = null;
            this.indexView.show_add_script_part_plus_button = null;
        },

        add_default() {
            this.add_script_part(0, "intro");
            this.add_script_part(1, "title");
            this.add_script_part(3, "outro");
        },
        triggerAutoSave() {
            this.autoSave.lastKeyUp = new Date();
            this.autoSave.status = 0;
        },
        save: function() {
            this.updateVideo({
                    update: {
                        ids: [this.vdo_id],
                        vdo_timeline: JSON.parse(JSON.stringify(this.script)),
                    },
                    queryParams : {
                        replace : true
                    }
                })
                .then((res) => {
                    if (res != undefined) {
                        this.script = res.vdo_timeline || [];
                        this.vdo_duration = res.vdo_duration || 0;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    snackbar.show({
                        text: "err modfication",
                        pos: snackbar.pos,
                        showAction: false,
                        backgroundColor: snackbar.backgroundColor,
                        duration: snackbar.duration,
                        textColor: snackbar.colorLevel["error"],
                    });
                });
        },
        deletePart(evt, index) {
            evt.stopPropagation();
            this.indexView.expanded = null;
            this.indexView.button = null;
            this.indexView.menuButton = null;
            this.indexView.show_add_script_part_plus_button = null;
            this.script.splice(index, 1);
            this.triggerAutoSave();

        },
        back: function() {
            this.$nextTick(() => {
                this.save();
                this.$emit("finished", {});
            });
        },
        mousePressedDown: function(mousePressEvent, index) {
            

            //VERIFY PRESS DOWN ZONE
            var undraggableZoneDetected = false;
            if ($(mousePressEvent.target).is("textarea")) {
                undraggableZoneDetected = true;
            } else if (
                $(mousePressEvent.target).parents().is(".addpart_hover_zone_expander")
            ) {
                undraggableZoneDetected = true;
            } else if ($(mousePressEvent.target).is(".undraggable")) {
                undraggableZoneDetected = true;
            } else if ($(mousePressEvent.target).parents().is(".undraggable")) {
                undraggableZoneDetected = true;
            }

            if (undraggableZoneDetected) {
                return false;
            } else{
                var isMouseMoved = false;
                var isMouseAlreadyUp = false;
    
                var dragFunctionHandler = (moveEvent) => {
                    if (isMouseAlreadyUp == false){
                        this.indexView.expanded = null;
                        isMouseMoved = true;
                        this.$nextTick(function() {
                            this.drag(index, mousePressEvent, moveEvent);
                        });
                    }
                };
    
                var mouseUpFunctionHandler = (mouseUpEvent) => {
                    isMouseAlreadyUp = true
                    this.cursorPosForScroll = null;
                    document.removeEventListener("mouseup", mouseUpFunctionHandler);
                    document.removeEventListener("mousemove", dragFunctionHandler);
    
                    //If there was not mouse move between the press down and the press up, it's a click
                    if (isMouseMoved == false) {
                        this.expand(mousePressEvent, index);
                    }
    
                    this.dragging_data.drag_started = false;
    
                    if (
                        this.dragging_data.index != null &&
                        this.dragging_data.hover_index != null
                    ) {
                        //Make de switch of indexes
                        this.drop(
                            this.dragging_data.index,
                            this.dragging_data.hover_index,
                            this.dragging_data.list_index
                        );
                    }
                };
                
                setTimeout(() => {
                    //LAUNCH MOUSE MOVE EVENT LISTENER AFTER 0.5 s (to prevent mouse moving during a single click)
                    document.addEventListener("mousemove", dragFunctionHandler);
                }, 200);
    
                //DETECT MOUSE UP TO RUN DROP METHODS
                document.addEventListener("mouseup", mouseUpFunctionHandler);
            }

        },
        drag: function(index, mousePressEvent, moveEvent, drag_started) {
            //FIRST MOVE
            if (this.dragging_data.drag_started == false) {
                //Hide all potentials adding pannel
                this.indexView.show_add_script_part_pannel = null;
                this.indexView.show_add_script_part_plus_button = null;
                this.indexView.expanded = null;

                //Getting list of index linked
                this.dragging_data.list_index.push(index);
                //count of element linked after the elem dragged
                var i = index;
                while (this.script[i] != undefined && this.script[i].linked == true ) {
                    if (this.dragging_data.list_index.includes(i + 1) == false) {
                        this.dragging_data.list_index.push(i + 1);
                    }
                    i++;
                }
                //count number of element linked before the elem droped
                i = index - 1;
                while (i >= 0 && this.script[i].linked == true) {
                    this.dragging_data.list_index.splice(0, 0, i);
                    i--;
                }

                this.dragging_data.index = index;

                //Create jqueryList selector for dragging elements
                var dragElements = this.dragging_data.list_index.map(
                    (dragElementIndex) => {
                        return `div.script_part:eq(${dragElementIndex})`;
                    }
                );

                //Adding class dragging (just for marking it) (And use for "not selection" jquery)
                $(dragElements.join(", ")).addClass("dragging");

                //Disable margin_top transition during the change
                $("div.script_part:not(.dragging)").css({
                    transition: "margin-top 0s",
                });

                //Get total height of dragging elements
                var totalHeight = dragElements
                    .map((dragElement) => {
                        return $(dragElement).outerHeight();
                    })
                    .reduce((a, b) => a + b, 0); //Make sum

                totalHeight =
                    totalHeight +
                    Number(
                        $(".script_part").css("--margin_bottom").replace(/px|%/g, "") *
                        (dragElements.length - 1)
                    );
                this.dragging_data.height = totalHeight;

                //Change size of margin top decay when drag is over antoher script part
                document.documentElement.style.setProperty(
                    "--margin_top_decay",
                    totalHeight + "px"
                );

                // Seting for first (before recalcuating during mouse move event) the hover index
                this.dragging_data.hover_index =
                    this.dragging_data.list_index[
                        this.dragging_data.list_index.length - 1
                    ] + 1;

                //Re set margin_top transition duration to intial value
                setTimeout(() => {
                    $("div.script_part:not(.dragging)").removeAttr("style");
                }, 200);

                //Save mouse offset compared to left-top coordonate of dragging elements
                this.dragging_data.list_index.forEach((index_from_linked_list) => {
                    this.dragging_data.shiftX_list.push(
                        mousePressEvent.clientX -
                        $(`div.script_part:eq(${index_from_linked_list})`)
                        .get(0)
                        .getBoundingClientRect().left
                    );
                    this.dragging_data.shiftY_list.push(
                        mousePressEvent.clientY -
                        $(`div.script_part:eq(${index_from_linked_list})`)
                        .get(0)
                        .getBoundingClientRect().top
                    );
                });

                //Make dragging elements fly
                $(dragElements.join(", ")).css({
                    position: "absolute",
                    width: "100%",
                    "z-index": 6,
                    cursor: "grabbing",
                });

                this.moveElement(moveEvent);

                this.dragging_data.drag_started = true;
            } else {
                this.moveElement(moveEvent);
            }
        },
        moveElement: function(moveEvent) {
            //IF ELEMENT IS CURRENT DRAGGING
            if (this.dragging_data.list_index.length != 0) {
                this.dragging_data.list_index.forEach(
                    (index_from_linked_list, index) => {
                        $(`div.script_part:eq(${index_from_linked_list})`).offset({
                            left: moveEvent.pageX - this.dragging_data.shiftX_list[index],
                            top: moveEvent.pageY - this.dragging_data.shiftY_list[index],
                        });
                    }
                );

                //AUTOSCROLL
                //If cursor is in 10% of bottom of screen
                //  Set this.cursorPosForScroll = down
                //  Launch scroll("down") method
                if (moveEvent.clientY > document.documentElement.scrollHeight * 0.9) {
                    this.cursorPosForScroll = "down";
                } else if (
                    moveEvent.clientY <
                    document.documentElement.scrollHeight * 0.1
                ) {
                    this.cursorPosForScroll = "up";
                } else {
                    this.cursorPosForScroll = null;
                }
            }
        },

        drop(srcIndex, targetIndex, srcIndexes) {
            (async () => {
            
                //Getting the next script part pos
                var nextScriptPartPos = $("div.script_part:not([dragging])")
                    .eq(targetIndex)
                    .offset();

                //Getting margin_top_decay
                var margin_top_decay = $(":root")
                    .css("--margin_top_decay")
                    .replace(/px|%/, "");
                var margin_bottom = $(".script_part")
                    .css("margin-bottom")
                    .replace(/px|%/, "");

                //Determine final position
                if (nextScriptPartPos != undefined) {
                    var finalPos = {
                        top: nextScriptPartPos.top -
                            Number(margin_top_decay) -
                            Number(margin_bottom),
                        left: nextScriptPartPos.left,
                    };
                } else {
                    //If next script part not found, getting the last script part position
                    var last = $("div.script_part").last();
                    var finalPos = {
                        top: last.offset().top + Number(margin_bottom) + last.outerHeight(),
                        left: last.offset().left,
                    };
                }

                //Set transition duration for left and top css property
                const transitionDuration =
                    Number(
                        $(".script_part").css("--movements_duration").replace(/ms/, "")
                    ) || 500;

                //Create jqueryList selector for dragging elements
                var dragElements = srcIndexes.map((dragElementIndex) => {
                    return `div.script_part:eq(${dragElementIndex})`;
                });

                //Getting totalHeightBefore of each drag elements
                //  For each drag elements => Get sum of other element dragged height before this element
                var list_totalHeightBefore = srcIndexes.map(
                    (index_from_srcIndexes, index) => {
                        return srcIndexes
                            .slice() // Make clone
                            .slice(0, index) // Delete element after index
                            .map((indexElementBefore) => {
                                return (
                                    $(`div.script_part:eq(${indexElementBefore})`).outerHeight() +
                                    Number(margin_bottom)
                                ); //Getting height and his margin-bottom
                            })
                            .reduce((a, b) => a + b, 0); //Make sum
                    }
                );

                await this.sleep(100);

                //Send dropped elements to his final position
                $(dragElements.join(", ")).each((index, dragElement) => {
                    $(dragElement)
                        .css({
                            transition: `left ${transitionDuration}ms ease-in-out, top ${transitionDuration}ms ease-in-out`,
                        })
                        .offset({
                            top: finalPos.top + list_totalHeightBefore[index],
                            left: finalPos.left,
                        });
                });

                //Waiting for drags elements take theire new place smoothly
                await this.sleep(transitionDuration);

                //Replace script part relatively
                $(dragElements.join(", "))
                    .css({
                        transition: `left 0s, top 0s`,
                        position: "relative",
                        left: 0,
                        top: 0,
                    })
                    .removeAttr("style")
                    .removeClass("dragging");

                //Delete temporarily margin-top transition
                $("div.script_part").css({
                    transition: "margin-top 0s",
                });

                //RECALCUL OF INDEXES POSITION
                if (targetIndex >= srcIndexes[srcIndexes.length - 1]) {
                    targetIndex = targetIndex - srcIndexes.length;
                }

                //console.debug('Drop : ', JSON.stringify(srcIndexes), " on ", targetIndex)

                //Backup element drop from script
                var elements = srcIndexes.reduce((results, elementIndex) => {
                    if (this.script[elementIndex] != undefined && this.script[elementIndex] != null ) results.push(this.script[elementIndex]) // modify is a fictitious function that would apply some change to the items in the array
                    return results
                }, [])

                //Delete element backed up from scrip
                srcIndexes.forEach((element, index) => {
                    this.script.splice(element - index, 1);
                });

                //Insert backed elements to target index
                this.script.splice(targetIndex, 0, ...elements);

                //Clean drag info
                this.dragging_data.index = null; //Disable de moving of dragged element
                this.dragging_data.list_index = []; //Clean linked list
                this.dragging_data.hover_index = null; //Disable the adding class "ydcay" on overred script part
                this.dragging_data.height = null;
                this.dragging_data.shiftY_list = [];
                this.dragging_data.shiftX_list = [];

                //Waiting for script switching
                await this.sleep(transitionDuration);

                //Restore moving transition initial duration
                $("div.script_part").each(function() {
                    $(this).removeAttr("style");
                });

                //Save changes
                this.triggerAutoSave();
            })();
        },

        expand(evt, index) {
            //VERIFY CLICK ZONE
            var unclickableZoneDetected = false;
            const interdiction_list = [
                "textarea",
                ".trash_hover_zone_expander",
                ".link_hover_zone_expander",
            ];
            if (evt != null) {
                //Verify target
                interdiction_list.forEach((interdiction) => {
                    if ($(evt.target).is(interdiction)) {
                        unclickableZoneDetected = true;
                    }
                });

                //Verify parents's target
                $(evt.target)
                    .parents()
                    .each((index, parent) => {
                        interdiction_list.forEach((interdiction) => {
                            if ($(parent).is(interdiction)) {
                                unclickableZoneDetected = true;
                            }
                        });
                    });
            }

            if (unclickableZoneDetected == false) {
                if (this.indexView.expanded == index) {
                    this.indexView.expanded = null;
                } else {
                    this.indexView.expanded = index;
                }
                this.$nextTick(function() {
                    this.autoResizeTextarea();
                });
            } else {
                return false;
            }
        },
        autoResizeTextarea() {
            $(".script_part.expanded")
                .find("textarea")
                .each(function() {
                    $(this)
                        .parents(".script_part")
                        .css({
                            //$(this).css({
                            "--textareaHeight": this.scrollHeight + "px",
                        });
                });
        },
        countWords(index) {
            if (this.script[index].scrpt_text != "") {
                var wordCount = this.script[index].scrpt_text.split(/\S+/g).length -1 || 0;
                if (wordCount == -1){ wordCount = 0 }
                return wordCount
            } else {
                return 0;
            }
        },
        addLink(indexTimeline) {
            this.script[indexTimeline].linked = !this.script[indexTimeline].linked;
            this.triggerAutoSave();
        },
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        show_add_script_part_pannel(evt, index) {
            if (this.indexView.show_add_script_part_pannel != index) {
                this.indexView.show_add_script_part_pannel = index;
                this.indexView.show_add_script_part_plus_button = null;
                this.indexView.expanded = null;

                //Animate the appearance of choice
                this.$nextTick(() => {
                    var i = 1;
                    $(
                        `.script_part:eq(${index}) > .addpart_hover_zone_expander > .add_part_to_script_pannel > div`
                    ).each((index, element) => {
                        setTimeout(() => {
                            $(element).addClass("show");
                        }, 60 * i);
                        i++;
                    });
                });
            } else {
                //Hide add_script_part_pannel
                this.indexView.show_add_script_part_pannel = null;
            }
        },

        //BUTTON RESET SCRIPT
        resetScript(evt){
            if (evt.target.getAttribute('secondClick') == "false"){
                evt.target.setAttribute('secondClick', "true")
                evt.target.classList.add('doubleValidation')
                evt.target.innerHTML = "Supprimer mon script"
            } else {
                this.script = []
                evt.target.setAttribute('secondClick', "false")
                evt.target.classList.remove('doubleValidation')
            }

        }
    },

};
</script>

<style lang="scss" src="./style.scss" scoped>

</style>

<style src="./link.css" scoped>

</style>

<style src="./welcoming.css" scoped>

</style>



