function countWord(script){
    var wordCount = 0;
    script.forEach(script_element => {
        wordCount += script_element.scrpt_text.split(/\n| /).length || 0;
    })
    return wordCount
}

//IF DURATION IN MINUTE IN MORE THAN 120 min, It's in MS
function detectAndFormatDurationToSeconds(duration){
    if (duration != undefined){
        
        var minutes = Math.floor(duration / 60);
        if ( minutes < 500){
            return duration
        } else {
            return duration/1000
        }
    } else {
        return 0
    }
}

function render(element){
    element = detectAndFormatDurationToSeconds(element)
    if (element != undefined){
        var minutes = Math.floor(element / 60);
        var seconds = (element - minutes * 60).toFixed(0)
        if (seconds < 10) { seconds = "0" + seconds }

        if (seconds == "00"){
            return `${minutes} min`
        } else if (minutes == 0){ 
            return `${seconds} s`
        } else {
            return `${minutes} min ${seconds} s`
        }


    } else {
        return '-'
    }
}

function renderShort(duration) {
    duration = detectAndFormatDurationToSeconds(duration)
    if (duration != undefined){
        var minutes = Math.floor(duration / 60);
        var seconds = (duration - minutes * 60).toFixed(0)
        if (seconds < 10) { seconds = "0" + seconds }

        return minutes + ":" + seconds

    } else {
        return '-'
    }
}

module.exports = {
    render,
    renderShort,
    countWord
}