<template>
    <div class="saveATemplate" >
        <div class="flexRow fristRow">
            <ImageComp class="image" :editable="true" :src_img="templateUsed.timeline_template_image != undefined ? templateUsed.timeline_template_image : null" @change="imageChanged"/>
            <input ref="template_name" type="text" :placeholder="templateUsed.timeline_template_name == undefined ? 'Name your template' :  templateUsed.timeline_template_name" v-model="templateUsed.timeline_template_name">
            <p class="button" button-type="" @click="saveTemplate">Sauvegarder comme template</p>
            <p class="button" button-type="delete" @click="deleteTemplate">Supprimer le template</p>
        </div>
        <div class="flexRow">
            </div>
    </div>
</template>



<script>
//LIBS
import file_api from "../../../../api/file"
import snackbar from "../../../Object/snackbar";

//COMPONENTS
import ImageComp from '../../../Object/EditableImage.vue'

//STORE
import Vuex from "vuex"

export default {
    name: "saveTemplate",
    props: ["templateUsed"],
    components: { ImageComp },
    data () {
            return {
                updated_image_file : undefined
        }
    },
    mounted(){
        if (this.templateUsed == undefined){
            this.templateUsed = {
                timeline_template_image : undefined,
                timeline_template_data : undefined,
                timeline_template_name : undefined,
                _id : undefined
            }
        }
    },
    watch : {
        templateUsed : {
            deep: true,
            handler : function(value, oldValue){
                //template value data from script pannel are by default on undefined. So to prevent erase name input value, tadam. 
                if (value.timeline_template_name == undefined || value.timeline_template_name == ""){
                    this.templateUsed.timeline_template_name = oldValue.timeline_template_name
                }
            }
        }
    },
    methods: {
        ...Vuex.mapActions([
            "create_timeline_template",
            "update_timeline_template",
            "delete_timeline_template"
        ]),
        imageChanged(event){
            //console.log("image change : ", event.target.file)
            this.updated_image_file = event.target.file
            
        },
        saveTemplate(){
            (async()=>{
                if (this.templateUsed.timeline_template_data == undefined || this.templateUsed.timeline_template_data == []){
                    snackbar.show({
                        text: "Impossible d'enregistrer un template vide"
                    })
                }

                if (this.updated_image_file != undefined){
                    //Image Upload
                    var media = await file_api.post(this.updated_image_file)
                        .then((media) => { return media })
                    
                    this.templateUsed.timeline_template_image = media._id;
                }

                //Getting templateName
                //console.log("this.$refs.template_name", this.$refs.template_name)
                //this.templateUsed.timeline_template_name = this.$refs.template_name.value

                let promise;
                if (this.templateUsed._id == undefined){
                    //Creating new template
                    promise = this.create_timeline_template
                } else {
                    //Updating template
                    promise = this.update_timeline_template
                }
                await promise(this.templateUsed)
                    .then((res) =>{
                        //console.debug(res)
                        snackbar.show({
                            text: res,
                            pos: snackbar.pos,
                            showAction: false,
                            backgroundColor: snackbar.backgroundColor,
                            duration: snackbar.duration,
                            textColor: snackbar.colorLevel[2],
                        })
                    })
                    .catch(e =>{
                        console.error("[saveTemplate] error : ", e)
                        snackbar.show({
                            text: e,
                            pos: snackbar.pos,
                            showAction: false,
                            backgroundColor: snackbar.backgroundColor,
                            duration: snackbar.duration,
                            textColor: snackbar.colorLevel[0],
                        })
                    })
            })()
        },
        deleteTemplate(){
            if (this.templateUsed._id != undefined){
                this.delete_timeline_template(this.templateUsed._id)
                .then((res) =>{
                    snackbar.show({
                        text: res,
                        pos: snackbar.pos,
                        showAction: false,
                        backgroundColor: snackbar.backgroundColor,
                        duration: snackbar.duration,
                        textColor: snackbar.colorLevel[2],
                    })
                })
            }
        }
    },
}
</script>

<style scoped>
.saveATemplate{
    height: 100px;
}

.fristRow{
    position: relative;
    justify-content: center;
    align-content: center;
    margin-bottom: 16px;
    width: 100%;
    height: 64px;
}

.image{
    width: 60px;
    height: 60px;
    margin-right: 10px;
    position: relative;
    border : var(--border_weight) solid var(--color_border);
    border-radius : 12px;
    cursor: pointer;
}

.button{
    margin-left: 16px;
}
</style>